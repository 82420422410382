<template>
    <v-container class="pt-5 px-0" fluid>
        <v-container class="content">
            <Headline text="neuigkeiten" />

            <v-row v-if="news != null">
                <v-col sm="4">
                    <axz-img :id="news.image" dummy="news_dummy.png" />
                </v-col>
                <v-col sm="8">
                    <v-row no-gutters>
                        <span class="text-body-1 primary--text">axXez</span>
                    </v-row>
                    <v-row no-gutters>
                        <span class="text-h6 text-uppercase">{{news.title}}</span>
                    </v-row>
                    <v-row no-gutters class="py-2">
                        <span class="text-body-2 grey--text"><v-icon small left color="accent">far fa-calendar-alt</v-icon> {{UTCtoLocal(news.published)}}</span>
                        <span class="text-body-2 ml-4 grey--text"><v-icon small left color="accent">fas fa-user</v-icon> geschrieben von <span class="accent--text">{{news.writer}}</span></span>
                    </v-row>
                    <v-row no-gutters class="mt-2">
                        <span class="text-body-1 accent--text">{{news.short}}</span>
                    </v-row>
                    <v-row no-gutters class="mt-8">
                        <p class="text-body-2" style="overflow: hidden !important; text-overflow: ellipsis !important; white-space: break-spaces;">{{news.text}}</p>
                    </v-row>
                </v-col>
                
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    export default {
        props: [ 'news_id' ],
        data () {
            return {
                news: null
            }
        },
        methods: {
            load(){
                this.$db.news.get(this.news_id).then((news) => {
                    this.news = news;
                });
            }
        },
        watch: {
            news_id () {
                //console.log("news id changed");
                if(this.news_id != null)
                    this.load();
            }
        },
        mounted (){
            this.load();
        }
    }
</script>

<style scoped>

</style>